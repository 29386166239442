import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="wrapper">
            <header>
              <h1 className="logo">
                <a href="/">Neutrogena</a>
              </h1>
              <nav>
                <a href="/produkty/" className="link">
                  Produkty
                </a>
                <span>| </span>
                <a href="/neutrogena/o-nas/" className="link">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>| </span>
                <a href="/budte-krasna/" className="link">
                  Buďte krásná
                </a>
                  
                  
              </nav>
            </header>
            <div className="content">
              <div className="breadcrumb">
                <a href="/">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>&gt;</span>
                <a href="/produkty/">Popis</a>
                <span>&gt;</span>
                <a href="/product/146/#" className="blue">
                  {" "}
                  <p>
                    NEUTROGENA<sup>®</sup> Retinol Boost Oční krém
 
                  </p>{" "}
                </a>
              </div>
              <div className="singleproduct singleproduct-new">
                <div className="prodimgcont prod-img">
                  <div className="bot">
                    <img
                      src="/assets/Uploads/NEUTROGENA_Retinol_Boost_Eye_Cream_Box+Tube.png"
                      alt="NEUTROGENA® Retinol Boost Oční krém"
                    />
                  </div>
                </div>
                <div className="prod-info info new-product">
                  <h2>
                    {" "}
                    <p>
                      NEUTROGENA<sup>®</sup> Retinol Boost Oční krém
                    </p>
                  </h2>
                  <dl>
                    <dt>Popis:</dt>
                    <dd>
                      <p>
                      Neparfemovaný oční krém uvolňuje do vrchní vrstvy pokožky čistý Retinol a tak <strong>účinně bojuje proti viditelným známkám stárnutí:  jemné linky, vějířky a unavený vzhled očního okolí.</strong> Hydratuje. Pro mladší a zdravý vzhled pokožky.<br/>
                      </p>
                      <br/>
                      <p>
                      Tak účinný, že <strong>u 100 % testujících byl potvrzen viditelný efekt.*</strong> 
                      </p>  
                      <br/>
                      <p>
                      <strong>VĚDECKY POTVRZENÉ VÝSLEDKY:</strong><br/>
                      - Vyživuje &amp; vyhlazuje pleť<br/>
                      - Zjemňuje linky a vějířky okolo očí<br/>
                      - Dodává svěží, rozjasněný vzhled<br/>
                      </p> 
                      <br/>
                      <p>
                      *Vědecké posouzení dermatology, 36 testujících, používání 1x denně.<br/>
                      </p> 
                      <br/>
                      <p>
                      <strong>ÚČINNÉ SLOŽKY:</strong><br/>
                      <strong>Čistý Retinol</strong> je forma vitamínu A uznávaná dermatology. Je vědecky prokázáno, že tato vysoce účinná látka zrychluje produkci kolagenu a obnovu buněk na povrchu pokožky.<br/>
                      <strong>Výtažek z myrty</strong> pro zvýšení účinnosti Retinolu.<br/>
                      <strong>Kyselina hyaluronová</strong> pro intenzivní hydrataci a vyplnění pleti.<br/>
                      </p>                
                    </dd>
                  </dl>
                  <dl>
                    <dt>Použití:</dt>
                    <dd>
                    <p>
                    Jednou denně naneste tři malé kapky krému na oblast pod očima a jemně vklepejte kolem očních kontur. Vyhněte se přímému kontaktu s očima.<br/>
                    </p>
                    <br/>
                    <p>
                    <strong>Pokud s Retinolem začínáte:</strong> Je důležité zavádět Retinol do péče o pleť postupně. V prvních 3 týdnech používejte pouze 2-3x týdně. Pokud vaše pleť přípravek dobře snáší, zvyšte frekvenci použití až na 1x denně.<br/>
                    </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>UPOZORNĚNÍ:</dt>
                    <dd>
                      <p>
                      Zarudnutí, pocit pálení, brnění nebo loupání jsou běžné dočasné projevy potvrzující, že přípravek účinkuje. Pokud vám způsobují diskomfort, omezte používání na 1x každý druhý den, dokud si pokožka nezvykne. Pokud kožní reakce přetrvává, ukončete používání.<br/>
                      </p>
                      <br/>
                      <p>
                      Zabraňte kontaktu s očima.<br/>
                      </p>
                      <br/>
                      <p>
                      Použijte přípravek na ochranu před slunečním zářením, protože retinol může zvýšit citlivost na slunce.<br/>
                      </p>
                    </dd>
                  </dl>
                  <dl className="last">
                    <dt>Balení:</dt>
                    <dd>15 ml</dd>
                  </dl>
                </div>
                <div id="fb_35"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="bluebar">
          <div className="bar">
            <div className="wrapper">
              <a href="/neutrogena/o-nas/">
                O ZNAČCE NEUTROGENA<sup>®</sup>
              </a>
              <span>|</span>
              <a href="/neutrogena/norska-receptura/">Norská receptura</a>
              <span>|</span>
              <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
              <span>|</span>
              <a href="/produkty/">
                Zobrazit produkty NEUTROGENA<sup>®</sup>
              </a>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
